.project__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
}

.project__item {
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.project__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.project__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.project__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.project__img {
  width: 295px;
  max-height: 10rem;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.project__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.project__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.project__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.project__button:hover .project__button-icon {
  transform: translateX(0.25rem);
}

/* ACTIVE PROJECT */
.active__project {
  background-color: var(--title-color);
  color: var(--container-color);
}

/* BREAK POINTS */
@media screen and (max-width: 992px) {
  .project__container {
    gap: 1.25rem;
  }

  .project__card {
    padding: 1rem;
  }

  .project__img {
    margin-bottom: 0.75rem;
  }

  .project__title {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .project__container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 620px) {
  .project__filters {
    flex-direction: column;
  }
  .project__item {
    margin: 0.25rem 0;
  }
  .project__item:hover {
    background-color: var(--title-color);
    color: var(--container-color);
  }
}

@media screen and (max-width: 576px) {
  .project__container {
    grid-template-columns: 1fr;
  }

  .project__img {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .project__item {
    font-size: var(--small-font-size);
  }

  .project__filters {
    column-gap: 0.25rem;
  }
}

table {
  width: 100%;
  border-collapse: collapse; /* Collapse borders */
  border-radius: 1rem;
}

/* Style the table header and cells with lines */
th,
td {
  border: 1px solid rgba(0, 0, 0, 0.1); /* Add a solid black border */
  padding: 8px; /* Add some padding */
  text-align: left; /* Align text to the left */
}

/* Add a little style to the table headers if you like */
thead th {
  background-color: #f2f2f2; /* Light grey background */
}

.skills__container {
  /* grid-template-columns: repeat(2, 350px); */
  /* column-gap: 3rem; */
  display: flex;
  margin: 0 10rem;
  justify-content: center;
  /* align-items: flex-start; */
}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
  height: fit-content;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  width: 100%;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  width: 100%;
  align-items: flex-start;
  row-gap: 1rem;
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  padding: 1rem 0;
}

.skills__data {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.skills__img {
  width: 6rem;
}

.skills svg {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--smaller-font-size);
}

/* BREAK POINTS */
@media screen and (max-width: 1420px) {
  .skills__container {
    margin: 0 7rem;
  }
}
@media screen and (max-width: 992px) {
  .skills__container {
    margin: 0 5.5rem;
  }
}

@media screen and (max-width: 576px) {
  .skills__container {
    margin: 0 1.5rem;
  }

  .skills__content {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .skills__container {
    margin: 0 0.1rem;
  }

  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}
